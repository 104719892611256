import CONSTANTS from './constants';
import { getBrand } from '../../utils';

const initialState = {
  /* eslint-disable no-undef */
  theme: 'vision-theme', // FxmModule.getStoredData('theme') ? FxmModule.getStoredData('theme') : `${getBrand()}-theme`
};

export default function themeReducer(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.UPDATE_THEME:
      FxmModule.store('theme', action.data);
      return { ...state, theme: action.data };
    default:
      return state;
  }
}
