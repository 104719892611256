/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { connect } from 'react-redux';
import KeyMap from '@foxxum-modules/virtual-keys';
// fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faBackspace, faGlobeAmericas, faAngleDown } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios/index';
import Logger from '@foxxum-modules/logger';
import * as utils from './utils';
import history from './storage/history';
import Loading from './views/loading';
import Routes from './storage/routes';

library.add(faCheck);
library.add(faBackspace);
library.add(faGlobeAmericas);
library.add(faAngleDown);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { initialized: false };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.controlKey);
    window.addEventListener('load', this.checkTosBeforeLoad);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentWillUnmount() {
    window.removeEventListener('keydown', this.controlKey);
    window.removeEventListener('load', this.checkTosBeforeLoad);
  }

  controlKey = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.alreadyPressed) return;
    this.alreadyPressed = true;
    setTimeout(() => {
      this.alreadyPressed = false;
    }, 150);

    switch (event.keyCode) {
      case KeyMap.VK_UP:
        this.$focus.toUp();
        break;
      case KeyMap.VK_RIGHT:
        this.$focus.toRight();
        break;
      case KeyMap.VK_DOWN:
        this.$focus.toDown();
        break;
      case KeyMap.VK_LEFT:
        this.$focus.toLeft();
        break;
      case KeyMap.VK_ENTER:
        this.$focus.click();
        break;
      case KeyMap.VK_BACK_SPACE:
      case KeyMap.VK_BACK:
        if (window.location.href.indexOf('#/home') !== -1) window.close();
        else history.goBack();
        break;
      default:
        break;
    }
  };

  checkTosBeforeLoad = () => {
    /* eslint-disable no-undef */
    axios
      .post(
        `${configuration.serverUrl}/frontend/modules/ajax/device/register`,
        `portalId=${configuration.portalId}${
          utils.getCookie('DeviceId') ? `&deviceId=${utils.getCookie('DeviceId')}` : ''
        }`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then((response) => {
        utils.setCookie('DeviceId', response.data.id, 3600);
        tosStatus = response.data.tos_accepted === '1';
        this.loadScripts();
      });
  };

  newScript(src) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.addEventListener('load', () => {
        resolve();
      });
      script.addEventListener('error', (e) => {
        reject(e);
      });
      document.body.appendChild(script);
    });
  }

  loadScripts() {
    const allScripts = [
      '/frontend/modules/FxmModule/v1/javascript/Category.js',
      '/frontend/modules/FxmModule/v1/javascript/Recommendation.js',
      '/frontend/modules/FxmModule/v1/javascript/App.js',
      '/frontend/modules/FxmModule/v1/javascript/Movie.js',
      '/frontend/modules/FxmModule/v1/javascript/Video.js',
      '/frontend/modules/FxmModule/v1/javascript/Genre.js',
      '/frontend/modules/FxmModule/v1/javascript/ExternalVideo.js',
    ];
    /* eslint-disable no-loop-func */
    for (let i = 0; allScripts.length > i; i++) {
      this.newScript(allScripts[i]).then(() => {
        /* eslint-disable no-undef  */
        if (allScripts.length - 1 === i) {
          this.loadPage();
        }
      });
    }
  }

  loadPage() {
    device = new FxmModule.Device();
    $.when(device.isInitialized).done(
      $.proxy(() => {
        portal = new FxmModule.Portal({
          portalId: configuration.portalId,
          device,
        });

        portal.getAppCategories((response) => {
          let allAppsId = 0;
          for (let j = 0; j < response.length; j++) {
            if (response[j].data.OrginalTitle === 'All') {
              allAppsId = j;
            }
          }
          response[allAppsId].getApps(
            {
              languages: '1',
              description_long: '1',
              categories: '1',
              sortby: 'portallanguage',
              sortingvalue: device.portalLanguage.data.Code,
              resolution: '1',
            },
            (rsp) => {
              allApps = rsp;
              utils.getInstalledApps(() => {
                if (installedApps) {
                  console.info(installedApps);
                  setTimeout(function () {
                    for (let i = 0; i < allApps.length; i++) {
                      if (
                        (installedApps.indexOf(allApps[i].data.ID) !== -1 && !allApps[i].isOnWatchlist) ||
                        (allApps[i].isOnWatchlist && installedApps.indexOf(allApps[i].data.ID) === -1)
                      ) {
                        allApps[i].watchlistAction();
                      }
                    }
                  }, 200);
                }

                this.setState({ initialized: true });
              });
            }
          );
        });

        dailymotion = new FxmModule.Dailymotion({
          apiKey: configuration.dailymotionApi,
        });

        youtube = new FxmModule.Youtube({
          apiKey: configuration.youtubeApi,
        });

        foxxalicious = new FxmModule.Foxxalicious({
          portal,
          apiVersion: 'live',
          externalObjects: [{ object: youtube, template: 'youtube' }],
        });

        settings = new FxmModule.Settings({
          portal,
        });

        searchFoxxalicious = new FxmModule.Search({
          searchObjects: [portal, foxxalicious, youtube, dailymotion],
          searchFoxxaliciousTypes: ['TV', 'Music', 'Movies'],
          templates: {
            movies: 'movie',
            tv: 'videoContent',
            music: 'videoContent',
            apps: 'appsRecommended',
          },
        });

        /* eslint-disable no-undef */
        FxmModule.store('showHint', true);
        const lastAppId =
          typeof FxmModule.getStoredData('lastAppId') !== 'undefined' ? FxmModule.getStoredData('lastAppId') : false;
        const lastAppReferer =
          typeof FxmModule.getStoredData('lastAppReferer') !== 'undefined'
            ? FxmModule.getStoredData('lastAppReferer')
            : false;
        const lastMovieId =
          typeof FxmModule.getStoredData('lastMovieId') !== 'undefined'
            ? FxmModule.getStoredData('lastMovieId')
            : false;
        if (lastAppId || lastMovieId) {
          if (lastAppId) {
            history.push(`/${lastAppReferer || 'apps'}/${lastAppId}`);
          }
          if (lastMovieId) {
            history.push(`/movies/${lastMovieId}`);
          }
          if (FxmModule.storageType === 'storage') {
            localStorage.removeItem('lastAppId');
            localStorage.removeItem('lastMovieId');
          } else {
            utils.deleteCookie('lastAppId');
            utils.deleteCookie('lastMovieId');
          }
        }
      }, this)
    );
  }

  render() {
    const { theme } = this.props;
    const { initialized } = this.state;
    return (
      <div id="app" className={theme}>
        <div id="fake" />
        {initialized && <Routes />}
        {!initialized && <Loading />}
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    theme: state.customReducer.theme,
  };
};
export default connect(mapStateToProps)(App);
