/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import KeyMap from '@foxxum-modules/virtual-keys';
import Focus from '@foxxum-modules/focus';
import Logger from '@foxxum-modules/logger';
import { Provider } from 'react-redux';
import { isProduction } from './utils';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './storage/store';

import './polyfills';

require('es6-promise').polyfill();

Logger.init({
  screenId: 'fxm_logger_screen',
  screenWrapperId: 'fxm_logger_wrapper_screen',
  console: ['log', 'info', 'warn', 'error', 'debug'],
  screen:
    window.location.href.indexOf('http://2021122248.staging.foxxum.com/') !== -1
      ? ['log', 'info', 'warn', 'error', 'debug']
      : [],
});

if (!isProduction()) {
  window.$keymap = KeyMap;
  window.$focus = Focus;
}

React.Component.prototype.$focus = Focus;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
